import { request } from '@helpers/fetch';
import {
  Cart,
  CartPaymentResponse,
  CartUpdatePayload,
  CartUpdateItemPayload,
  ApplyCouponPayload,
  ToggleTypePayload,
  CART_SOURCE,
  CartRemoveItemPayload,
  CreatePaymentPayload,
  TrackAddToCartPayload,
  CartBuyNowResponse,
  BuyNowPayload,
  CartEstimateResponse,
} from '@models/cart';
import { Response } from '@models/response';

export const apiCart = {
  get(): Promise<Response<Cart>> {
    return request.get('/api/v1/cart', undefined, commonHeaders);
  },
  update(payload: CartUpdatePayload): Promise<Response<Cart>> {
    return request.put('/api/v1/cart', payload, commonHeaders);
  },
  updateItem(payload: CartUpdateItemPayload): Promise<Response<Cart>> {
    return request.put('/api/v1/cart/update-item', payload, commonHeaders);
  },
  removeItem(payload: CartRemoveItemPayload): Promise<Response<Cart>> {
    return request.put('/api/v1/cart/remove-item', payload, commonHeaders);
  },
  removeAll(): Promise<Response<Cart>> {
    return request.put('/api/v1/cart/remove-all', undefined, commonHeaders);
  },
  toggleTypeItem(payload: ToggleTypePayload): Promise<Response<Cart>> {
    return request.put('/api/v1/cart/toggle-type-item', payload, commonHeaders);
  },
  createPayment(payload: CreatePaymentPayload): Promise<Response<CartPaymentResponse>> {
    return request.post('/api/v1/cart/payment', payload, commonHeaders);
  },
  buyNow(payload: BuyNowPayload): Promise<Response<CartBuyNowResponse>> {
    return request.post('/api/v1/cart/buy-now', payload, commonHeaders);
  },
  estimate(payload: BuyNowPayload): Promise<Response<CartEstimateResponse>> {
    return request.post('/api/v1/cart/estimate', payload, commonHeaders);
  },
  applyCoupon(payload: ApplyCouponPayload): Promise<Response<[]>> {
    return request.put('/api/v1/cart/coupon', payload, commonHeaders);
  },
  confirmPayment(): Promise<Response<[]>> {
    return request.put('/api/v1/cart/confirm', undefined, commonHeaders);
  },
  trackAddToCart(payload: TrackAddToCartPayload): Promise<Response<any>> {
    return request.post('/api/v1/track', {
      event: 'AddToCart',
      ...payload,
    });
  },
  getCouponData(payload: { coupon: string }): Promise<Response<{}>> {
    return request.get('/api/v1/cart/get-coupon-data', payload, commonHeaders);
  },
};

const getCartSource = (): CART_SOURCE|string => {
  if (location.pathname.includes('/pharmacy')) {
    return CART_SOURCE.PHARMACY;
  }

  if (location.pathname.includes('/quiz')) {
    return CART_SOURCE.QUIZ;
  }

  return '';
};

const commonHeaders = {
  headers: {
    'x-cart-source': getCartSource(),
  },
};
