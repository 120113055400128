export enum VARIANT_TYPE {
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_ADDON = 'subscriptionAddon',
  SINGLE = 'single'
}

export enum VARIANT_PERIOD_UNIT {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

export enum VARIANT_PRICE_MODEL {
  PER_UNIT = 'perUnit',
  FIXED = 'fixed'
}

export enum VARIANT_STATUS {
  LIVE = 'live',
  DISABLED = 'disabled'
}

export interface Variant {
  id: number;
  title: string;
  status: VARIANT_STATUS | null;
  enabled: boolean | null;
  dateCreated: string | null;
  dateUpdated: string | null;
  variantType: VARIANT_TYPE;
  variantAmount: number;
  variantPeriod: number | null;
  variantRefGroup: string;
  variantShipping: false;
  externalRefPrice: string;
  variantPeriodUnit: VARIANT_PERIOD_UNIT | null;
  variantPriceModel: VARIANT_PRICE_MODEL;
  variantCustomTitle: string | null;
  variantMaxPurchaseQuantity: null;
  shippable?: boolean;
  // Multiselect only
  $isDisabled?: boolean;
  dosageCycleEnabled: boolean;
  dosageCycle: VariantDosageCycle;
}

export interface VariantDosageCycle {
  cycleNumber: number;
  unitsDose: string;
  directions: string;
}
