<script setup lang="ts">

import { computed, toRefs } from 'vue';

type Props = {
  modelValue: boolean
  label?: string
  size?: 'sm' | 'md'
}

const props = withDefaults(defineProps<Props>(), { label: '', size: 'md' });
const { label, size, modelValue } = toRefs(props);
const emit = defineEmits<{ 'update:modelValue': [value: boolean] }>();

const value = computed({
  get: () => modelValue.value,
  set: (val) => {
    emit('update:modelValue', val);
  },
});

const switchClass = computed(() => ({
  'switch': true,
  'switch--size-md': size.value === 'md',
  'switch--size-sm': size.value === 'sm',
}));

const sliderClass = computed(() => ({
  'slider': true,
  'checked': value.value,
  'slider--size-md': size.value === 'md',
  'slider--size-sm': size.value === 'sm',
}));
</script>
<template>
  <div
    class="flex items-center"
    @click="value = !value"
  >
    <div :class="switchClass">
      <div
        :class="sliderClass"
        data-testpl="checkout-product-slider"
      />
    </div>
    <div
      v-if="label"
      class="ml-6 text-14 text-deep-blue"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </div>
  </div>
</template>
<style scoped lang="scss">
.switch {
  position: relative;
  display: inline-block;

  &--size-md {
    width: 70px;
    height: 40px;
  }

  &--size-sm {
    width: 50px;
    height: 26px;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-warm-grey);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &--size-md:before {
    height: 28px;
    width: 28px;
    left: 6px;
    bottom: 6px;
    transform: translateX(0);
  }

  &--size-sm:before {
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    transform: translateX(0);
  }

  &:before {
    position: absolute;
    content: "";
    background-color: white;
    border-radius: 34px;
    transition: transform 200ms;
  }

  &:focus {
    box-shadow: 0 0 1px var(--color-turquoise);
  }

  &.checked {
    background-color: var(--color-turquoise);

    &.slider--size-sm:before {
      transform: translateX(24px);
    }

    &.slider--size-md:before {
      transform: translateX(28px);
    }
  }
}
</style>
