import { Asset } from '@models/asset';
import { QuizCategory } from '@models/quiz';
import { Variant } from '@models/variant';

export interface Product {
  id: number;
  type: string
  productType?: PRODUCT_TYPE[]
  heading: string
  clarification: string
  title: string
  display: boolean
  fullfillmentType: string
  shippingAmount: number
  status: string
  url: string
  quizCategory: Pick<QuizCategory, 'id' | 'slug' | 'title'>
  image: Pick<Asset, 'id' | 'url'> | null
  variants: Variant[]
  dosages: number[]
  dosageUnit: string
  injectionAck: boolean
  // Multiselect only
  $isDisabled?: boolean
}

export interface ProductType {
  label: string
  value: string
}

export interface ProductCategory {
  id: number
  title: string
  completed: boolean
  categoryPath: string
  handle: string
}

export enum PRODUCT_DISPLAY_TYPE {
  SELECT = 'select',
  ADDONS = 'addOns',
  CARDS = 'cards',
  OPTIONS = 'options',
  RECOMMENDED = 'recommended'
}

export enum PRODUCT_TYPE {
  PILL = 'pill',
  TUBE = 'tube',
  TROCHE = 'troche',
  DIGITAL = 'digital',
  ADD_ON = 'addOn',
  INJECTION = 'injection'
}

export const PRODUCT_TYPE_MEASUREMENT_LABEL = {
  [PRODUCT_TYPE.PILL]: 'pills',
  [PRODUCT_TYPE.TUBE]: 'tubes',
  [PRODUCT_TYPE.TROCHE]: 'troches',
  [PRODUCT_TYPE.DIGITAL]: 'digitals',
  [PRODUCT_TYPE.ADD_ON]: 'add-ons',
};

export const PRODUCT_TYPE_LABEL = {
  [PRODUCT_TYPE.PILL]: 'Pill',
  [PRODUCT_TYPE.TUBE]: 'Tube',
  [PRODUCT_TYPE.TROCHE]: 'Troche',
  [PRODUCT_TYPE.DIGITAL]: 'Digital',
  [PRODUCT_TYPE.ADD_ON]: 'Add-on',
};

export enum PRODUCT_CATEGORY {
  WEIGHT_LOSS = 'weight-loss',
  SEXUAL_WELLNESS = 'sexual-wellness',
  ANTI_AGING = 'anti-aging',
  HAIRCARE = 'haircare',
  SKIN_CARE = 'skin-care'
}

export interface GetPharmacyResponse {
  products: Product[]
  categories: ProductCategory[]
  disabled: boolean
}

export interface GetPharmacyOnlyPrescribedResponse {
  products: Product[]
}

export enum PRODUCT_USAGE {
  DAILY = 'daily',
  BEFORE_SEX = 'before-sex'
}

export interface CartProduct {
  url: string;
  price: string;
  clarification: string | null
  min_price: number;
  product: {
    id: number;
    url: string;
    image: {
      id: number;
      url: string;
    },
    title: string;
    isMain: boolean | null;
    rxOnly: boolean;
    variants: Variant[];
    productType: PRODUCT_TYPE[];
    dosages: number[];
    dosageUnit: string;
    label: string | null;
    productUsage: ProductUsage[];
    shippingPrices: Array<{
      shippingAmount: number;
    }>
  }
}

export type ProductUsage = 'beforeSex' | 'daily';
